export const styles = {
    container: "bg-gray-50 dark:bg-gray-800 p-4 md:p-8 min-h-screen",
    title: "text-5xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 text-center",
    subtitle: "text-xl md:text-3xl text-gray-700 dark:text-gray-300 mb-8 text-center",
    mapContainer: "mb-8 md:mb-12 p-2 md:p-4 rounded-lg",
    landmarksContainer: "pb-32",
    landmarksTitle: "text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-8 text-center",
    categoryContainer: "bg-gray-100 dark:bg-gray-700 p-4 md:p-6 rounded-lg shadow-lg",
    categoryTitle: "text-2xl md:text-3xl font-semibold text-gray-900 dark:text-white mb-4 text-center",
    locationList: "space-y-4 md:space-y-6",
    locationItem: "bg-white dark:bg-gray-600 border rounded-lg p-2 md:p-4 shadow-md",
    locationHeader: "flex items-center space-x-2 md:space-x-4 mb-2 md:mb-4",
    locationIcon: "w-8 h-8 md:w-12 md:h-12",
    locationName: "text-lg md:text-2xl text-gray-900 dark:text-white",
    locationDescription: "text-sm md:text-lg text-gray-700 dark:text-gray-300",
    locationTime: "text-sm md:text-lg text-gray-700 dark:text-gray-300",
    seeMoreButton: "bg-blue-500 text-white text-sm md:text-base px-2 md:px-4 py-1 md:py-2 rounded-lg",
    seeLessButton: "bg-red-500 text-white text-sm md:text-base px-2 md:px-4 py-1 md:py-2 rounded-lg",
    buttonContainer: "flex justify-between mt-4",

    // skeleton
    skeletonCard: "bg-gray-200 dark:bg-gray-700 p-4 md:p-6 rounded-lg shadow-lg animate-pulse",
    skeletonCardContentContainer: "space-y-4",
    skeletonCardImageContainer: "h-32 bg-gray-300 dark:bg-gray-600 rounded",
    skeletonCardImage: "h-full w-full object-cover rounded",
    skeletonCardTextContainer: "space-y-2",
    skeletonLineLarge: "h-4 bg-gray-300 dark:bg-gray-600 rounded",
    skeletonLineMedium: "h-3 bg-gray-300 dark:bg-gray-600 rounded",
    skeletonLineSmall: "h-2 bg-gray-300 dark:bg-gray-600 rounded",
    skeletonGridContainer: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8",
};
