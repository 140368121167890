export const styles = {
    container: "bg-gray-50 dark:bg-gray-800 p-4 md:p-8 min-h-screen",
    title: "text-5xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 text-center",
    subtitle: "text-xl md:text-3xl text-gray-700 dark:text-gray-300 mb-8 text-center",
    skeletonItem: "w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow rounded-lg p-5 animate-pulse",
    skeletonLineLarge: "h-6 bg-gray-200 rounded-full dark:bg-gray-700 w-3/4 mb-4",
    skeletonLineMedium: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-1/2 mb-4",
    skeletonLineSmall: "h-4 bg-gray-200 rounded-full dark:bg-gray-700 w-full mb-2",
    skeletonContainer: "container mx-auto grid grid-cols-1 md:grid-cols-1 gap-5",
    errorModalContainer: "p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800",
    errorModalHeader: "text-lg font-medium",
    errorModalBody: "mt-2 mb-4 text-sm",
    errorButton: "text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800",
    errorDismissButton: "text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800",
    contactCard: "w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 shadow rounded-lg p-5",
    contactTitle: "text-lg font-semibold text-gray-900 dark:text-white mb-2",
    addressContainer: "relative bg-gray-50 dark:bg-gray-700 dark:border-gray-600 p-4 rounded-lg border border-gray-200 not-italic grid grid-cols-2",
    addressLabel: "space-y-2 text-gray-500 dark:text-gray-400 leading-loose hidden sm:block",
    addressDetails: "space-y-2 text-gray-900 dark:text-white font-medium leading-loose",
    copyButton: "absolute end-2 top-2 text-gray-500 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800 rounded-lg p-2 inline-flex items-center justify-center",
    actionButton: "text-white py-2 px-4 rounded mt-4",
    footerText: "text-sm md:text-base text-gray-500 dark:text-gray-400 mt-4 mb-0 text-center",
    ratingContainer: "flex items-center justify-center mt-8",
    ratingIcon: "w-4 h-4 text-yellow-300 me-1",
    ratingText: "ms-2 text-sm font-bold text-gray-900 dark:text-white",
    separatorDot: "w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400",
    ratingLink: "text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white",
    modalOverlay: "fixed inset-0 bg-black opacity-50",
    modalContainer: "bg-white dark:bg-gray-700 rounded-lg shadow-lg p-8 z-50",
    modalHeader: "flex justify-between items-center mb-4",
    modalTitle: "text-2xl font-bold text-gray-900 dark:text-white",
    closeButton: "text-gray-500 dark:text-gray-300 hover:text-gray-700 dark:hover:text-gray-100",
    closeIcon: "w-6 h-6",
    modalBody: "text-gray-700 dark:text-gray-300",
    modalActionButton: "mt-4 bg-blue-500 text-white py-2 px-4 rounded",
};