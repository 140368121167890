export const styles = {
  container: "bg-gray-50 dark:bg-gray-800 p-4 md:p-8 min-h-screen flex flex-col",
  title: "text-5xl md:text-6xl font-bold text-gray-900 dark:text-white mb-8 text-center",
  subtitle: "text-xl md:text-2xl text-gray-700 dark:text-gray-300 mb-8 text-center",
  skeletonContainer: "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-6 lg:gap-8",
  skeletonAccordionContainer: "space-y-4", 
  skeletonAccordionItem: "bg-gray-200 dark:bg-gray-700 p-4 md:p-6 rounded-lg shadow-lg animate-pulse",
  skeletonAccordionTitle: "h-4 bg-gray-300 dark:bg-gray-600 rounded mb-2",
  skeletonAccordionContent: "h-3 bg-gray-300 dark:bg-gray-600 rounded mb-1",
  errorModalContainer: "p-4 mb-4 text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800",
  errorModalHeader: "text-lg font-medium",
  errorModalBody: "mt-2 mb-4 text-sm",
  errorButton: "text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 me-2 text-center inline-flex items-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800",
  errorDismissButton: "text-red-800 bg-transparent border border-red-800 hover:bg-red-900 hover:text-white focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center dark:hover:bg-red-600 dark:border-red-600 dark:text-red-500 dark:hover:text-white dark:focus:ring-red-800",
  accordionButton: "flex items-center justify-between w-full p-5 font-medium rtl:text-right text-gray-500 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-700 gap-3",
  accordionContent: "p-5 border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900",
  footerContainer: "w-full md:w-3/4 p-4 text-center bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-auto mt-4",
  footerTitle: "mb-2 text-3xl font-bold text-gray-900 dark:text-white",
  footerText: "mb-5 text-base text-gray-500 sm:text-lg dark:text-gray-400",
  ratingContainer: "flex items-center mt-4 mx-auto",
  ratingText: "ms-1 text-sm font-medium text-gray-500 dark:text-gray-400",
  ratingLink: "text-sm font-medium text-gray-900 underline hover:no-underline dark:text-white",
  separatorDot: "w-1 h-1 mx-1.5 bg-gray-500 rounded-full dark:bg-gray-400",
  icon: "w-5 h-5 me-2 shrink-0",
  ratingIcon: "w-4 h-4 text-yellow-300 me-1",
};
