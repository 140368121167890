export const styles = {
    navContainer: "bg-white border-gray-200 dark:bg-gray-900",
    navContent: "max-w-screen-l flex flex-wrap items-center justify-between mx-auto p-2",
    logoContainer: "flex items-center space-x-3 rtl:space-x-reverse",
    logoImage: "h-12",
    logoText: "self-center text-2xl font-semibold whitespace-nowrap dark:text-white",
    menuButton: "inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600",
    menuIcon: "w-5 h-5",
    menuContainer: (isOpen: boolean) => `${isOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`,
    menuList: "font-medium flex flex-col p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:justify-between rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700",
    menuItem: "block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-blue-700 md:p-0 dark:text-white md:dark:hover:text-blue-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent text-lg md:text-xl",
};
